import { useEffect } from "react";

const IntercomChat = () => {
  useEffect(() => {
    window.intercomSettings = {
      app_id: "som9dw7r", // Your actual Intercom app ID
      // You can include other settings here as needed, but no user-specific details
    };

    (function() {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic('reattach_activator');
        ic('update', window.intercomSettings);
      } else {
        var d = document;
        var i = function() { i.c(arguments); };
        i.q = [];
        i.c = function(args) { i.q.push(args); };
        w.Intercom = i;

        var l = function() {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/sogfdgfdw7r'; // Your actual Intercom app ID
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };

        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }, []);

  return null; // This component does not render anything
};

export default IntercomChat;
