import React from 'react';
import './CancelPage.css'; // Make sure the path to your CSS file is correct

const CancelPage = () => {
  return (
    <section>
      <p>Forgot to add something to your cart? Shop around then come back to pay!</p>
    </section>
  );
};

export default CancelPage;
